// var stickyHeader = false;
var transformHomepageDonationAmountImages = true;
// var flyoutNav = false;
// var fullscreenNav = false;
var homeQuickGivingPreFooter = false;
var countUpStats = true;
var countUpStatsDuration = 3000;
// var subsiteHijackMainNav = ['dontgiveup'];
// var subsiteHijackMainLogoClickDestination = ['dontgiveup'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// DES-4982 Wrap YouTube iframes to fix aspect ratio
if ($('body').hasClass('pagesPostBody')) {
    $('.postContent iframe[src*="youtube.com"]').each(function(){
        $(this).wrap('<div class="videoWrapper"></div>')
    })
}

// Subsite stuff
$(".subsite.homepage .subsiteTitle").clone().appendTo(".headerWrapperSubsite");


var quickGivingIntro = $(".subsite #bodyForm > p, .subsite #bodyForm > h2, .subsite #bodyForm > h3, .subsite #bodyForm > h4");
quickGivingIntro.addClass("quickGivingIntro").prependTo(".subsite .quickGivingPanel");


// Subsite - quick giving panel
if ($(window).width() < 768){	
    $(".subsite .donationAmountOther .donationAmountFigure").text("Enter your own amount");
};

$(".homefeaturecategory-homeboxquickgiving").insertBefore(".homeIntroWrapper");

